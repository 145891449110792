<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <form @submit.prevent="emitData" ref="formModalRight">
      <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{dataModal.name}}</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
      </div>
      <vs-divider class="mb-0"></vs-divider>

      <VuePerfectScrollbar class="scroll-area--data-list-add-new pt-4 pb-6"
                           style="height: 580px; overflow: auto"
                           :settings="settings">
        <div class="p-6" :key="keyF" v-for="(field, keyF) of dataModal.fields">
          <vs-input v-model="field.value" :label="field.text" v-if="field.type == 'text'" :name="field.name"
                    class="mt-5 w-full" :required="field.required"/>

          <vs-select v-model="field.value" :label="field.text" v-if="field.type == 'select'" class="mt-5 w-full"
                     :required="field.required" @change="field.change($event)">
            <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in field.options"/>
          </vs-select>

          <vs-textarea v-model="field.value" :label="field.text" rows="5" v-if="field.type == 'textbox'"
                       :name="field.name" class="mt-5 w-full" :required="field.required"/>

          <div v-if="field.type == 'checkboxes' && field.options.length > 0"
               style="height: 150px; overflow: auto; margin-top: -15px">
            <label style="font-size: 12px">{{field.text}}</label>
            <vs-checkbox class="mt-2" v-model="field.options[i].value" :name="field.name" :key="item.id"
                         v-for="(item,i) in field.options">{{item.field}}
            </vs-checkbox>
          </div>

          <table v-if="field.type == 'table'" width="100%">
            <thead>
            <tr>
              <th v-for="opt in field.options" :key="opt">{{opt}}</th>
              <th style="text-align: right">
                <vs-icon type="flat" icon-pack="feather" icon="icon-plus" radius color="primary"
                         @click.native="add(keyF, 'compare')" style="cursor: pointer"></vs-icon>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(opt, key) in field.compare" :key="key">
              <td width="50%">
                <vs-select v-model="opt.destination.value" class="pr-2 w-full">
                  <vs-select-item :key="item" :value="item" :text="item"
                                  v-for="item in opt.destination.options"/>
                </vs-select>
              </td>
              <td width="50%">
                <vs-select v-model="opt.origin.value" class="pr-2 w-full">
                  <vs-select-item :key="item" :value="item" :text="item"
                                  v-for="item in opt.origin.options"/>
                </vs-select>
              </td>
              <th style="text-align: right">
                <vs-icon type="flat" v-if="key > 0" icon-pack="feather" icon="icon-trash" radius color="danger"
                         @click.native="remove(keyF, 'compare', key)" style="cursor: pointer"></vs-icon>
              </th>
            </tr>
            </tbody>
          </table>

          <table v-if="field.type == 'table_list'" width="100%" cellspacing="10">
            <thead>
            <tr>
              <th>Nombre</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(opt, key) in field.options" :key="key"
                style="background: rgba(166,168,168,0.45); cursor: pointer"
                @click="field.click(dataModal.data.parent, key)">
              <td style="padding: 5px;">{{opt.name}} - {{opt.table}}</td>
            </tr>
            </tbody>
          </table>
        </div>

      </VuePerfectScrollbar>

      <div class="flex flex-wrap items-center justify-center p-6" slot="footer">
        <vs-button class="mr-6" v-if="dataModal.txtSendBtn != ''">{{dataModal.txtSendBtn}}</vs-button>
        <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      </div>
    </form>
  </vs-sidebar>
</template>

<script>

  import VuePerfectScrollbar from 'vue-perfect-scrollbar'

  export default {
    props: {
      dataModal: Object,
      isSidebarActive: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        settings: {
          maxScrollbarLength: 60,
          wheelSpeed: .60,
        },
      }
    },
    computed: {
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive
        },
        set(val) {
          if (!val) {
            this.$emit('closeSidebar');
            this.initValues();
          }
        }
      }
    },
    methods: {
      initValues() {
        this.name = '';
        this.category = 'audio';
        this.order_status = 'pending';
        this.price = '';
      },
      emitData($event) {
        this.$emit('eventsend');
      },
      remove(posF, key, pos) {
        this.dataModal.fields[posF].compare.pop();
      },
      add(posF, key) {

        var dataOrigin = [], datDestination = [];

        for (const fie of this.dataModal.fields[posF].compare[0].origin.options) {
          dataOrigin.push(fie);
        }

        for (const fie of this.dataModal.fields[posF].compare[0].destination.options) {
          datDestination.push(fie);
        }

        this.dataModal.fields[posF].compare.push({
          origin: {
            value: '',
            options: dataOrigin
          },
          destination: {
            value: '',
            options: datDestination
          }
        });
      }
    },
    components: {
      VuePerfectScrollbar,
    }
  }
</script>

<style lang="scss" scoped>
  .add-new-data-sidebar {
    /deep/ .vs-sidebar--background {
      z-index: 52010;
    }

    /deep/ .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;

      .img-upload {
        margin-top: 2rem;

        .con-img-upload {
          padding: 0;
        }

        .con-input-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .scroll-area--data-list-add-new {
    height: calc(100% - 4.3rem);
  }
</style>
