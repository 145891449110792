<template>
  <div>
    <right-modal v-on:eventsend="onEmit" :dataModal="dataRightModal" :isSidebarActive="RightModal"
                 @closeSidebar="RightModal = false"/>
    <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
      <div class="flex flex-wrap-reverse items-center">
        <div
          class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
          @click="RightModal = true">
          <feather-icon icon="PlusIcon" svgClasses="h-4 w-4"/>
          <span class="ml-2 text-base text-primary">Nuevo</span>
        </div>
      </div>
    </div>
    <div class="vx-row" id="content">
      <div class="vx-col w-full md:w-1/2" v-for="model in models" :key="model.idticket_modelo">
        <vx-card
          :title="model.nombre"
          title-color="primary"
          :subtitle="model.descripcion">
          <template slot="actions">
            <vx-tooltip text="Editar" style="float: left" delay=".5s">&nbsp;
              <feather-icon @click="getModel(model.idticket_modelo)" icon="EditIcon"
                            svgClasses="h-5 w-5"/>
            </vx-tooltip>
            <vx-tooltip text="Agregar campo" style="float: left" delay=".5s">&nbsp;
              <feather-icon @click="newField(model.idticket_modelo)" icon="PlusCircleIcon"
                            svgClasses="h-5 w-5"/>
            </vx-tooltip>
          </template>
          <draggable class="vx-row" @end="checkMove(model.idticket_modelo)" v-model="model.D_idticket_modelo"
                     style="margin-top: -40px; margin-bottom: 0; height: 150px; overflow: auto">
            <div class="vx-col w-full md:w-1/3" v-for="field in model.D_idticket_modelo"
                 :key="field.idticket_modelo_campo">
              <vs-input style="width: 95%; margin-bottom: -11px;" icon-pack="feather"
                        :icon="getTypeField(field.idtipo_campo)"
                        :label-placeholder="field.nombre"
                        :disabled="true" :value="field.nombre"/>
            </div>
          </draggable>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>

  import ServicesAPI from "@/services/api-services";
  import RightModal from "./RightModal.vue";
  import draggable from 'vuedraggable'

  export default {
    data() {
      return {
        isMounted: false,
        models: [],
        fields_type: [],
        RightModal: false,
        dataRightModal: {
          name: 'Nuevo modelo',
          id: 'newModel',
          data: {},
          fields: [
            {text: 'Nombre', name: 'nombre', type: 'text', required: true, value: ''},
            {
              text: 'Estado', name: 'activo', type: 'select', required: true, options: [
                {value: 0, text: 'Inactivo'},
                {value: 1, text: 'Activo'}
              ],
              value: ''
            },
            {text: 'Descripción', name: 'descripcion', type: 'textbox', required: true, value: ''},
          ],
          txtSendBtn: 'Registrar'
        }
      }
    },
    methods: {
      setDefaultRightModal() {

        this.dataRightModal = {
          name: 'Nuevo modelo',
          id: 'newModel',
          data: {},
          fields: [
            {text: 'Nombre', name: 'nombre', type: 'text', required: true, value: ''},
            {
              text: 'Estado', name: 'activo', type: 'select', required: true, options: [
                {value: 0, text: 'Inactivo'},
                {value: 1, text: 'Activo'}
              ],
              value: ''
            },
            {text: 'Descripción', name: 'descripcion', type: 'textbox', required: true, value: ''},
          ],
          txtSendBtn: 'Registrar'
        }
      },
      getFieldTypes() {

        ServicesAPI.methods.apiGET({
          url: 'fields',
          function: 'obtener_campos',
          params: {}
        }, res => {
          this.fields_type = res.data.tipo_campo;
        });
      },
      getTypeField(type) {

        if (this.fields_type.filter(field => field.idtipo_campo == type).length)
          return this.fields_type.filter(field => field.idtipo_campo == type)[0].icono;
      },
      newField(model) {

        const fields = [];

        for (const field of this.fields_type) {
          fields.push({
            value: field.idtipo_campo,
            text: field.nombre
          })
        }

        this.dataRightModal = {
          name: 'Nuevo campo',
          id: 'newField',
          data: {
            idticket_modelo: model
          },
          fields: [
            {
              text: 'Tipo', name: 'idtipo_campo', type: 'select', required: true, options: fields,
              value: fields[0].value
            },
            {text: 'Nombre', name: 'nombre', type: 'text', required: true, value: ""},
            {
              text: 'Estado', name: 'activo', type: 'select', required: true, options: [
                {value: 0, text: 'Inactivo'},
                {value: 1, text: 'Activo'}
              ],
              value: ""
            },
          ],
          txtSendBtn: 'Registrar'
        };
        this.RightModal = true;
      },
      onEmit() {

        switch (this.dataRightModal.id) {
          case 'newModel':

            var params = {
              idticket_modelo: this.uniqid(3),
              idusuario_registro: localStorage.getItem("MH_USER")
            };

            for (const field of this.dataRightModal.fields) {
              params[field.name] = field.value;
            }

            ServicesAPI.methods.apiPOST({
              url: 'models',
              function: 'registrar',
              params: params
            }, () => {
              this.RightModal = false;
              this.getModels();
            });
            break;
          case 'editModel':

            var params = {
              idticket_modelo: this.dataRightModal.data.idticket_modelo,
              idusuario_modificacion: localStorage.getItem("MH_USER"),
              modificacion: this.$moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
            };

            for (const field of this.dataRightModal.fields) {
              params[field.name] = field.value;
            }

            ServicesAPI.methods.apiPUT({
              url: 'models',
              function: 'modificar',
              params: params
            }, () => {
              this.RightModal = false;
              this.setDefaultRightModal();
              this.getModels();
            });
            break;
          case 'newField':

            var params = {
              idticket_modelo_campo: this.uniqid(3),
              idticket_modelo: this.dataRightModal.data.idticket_modelo
            };

            for (const field of this.dataRightModal.fields) {
              params[field.name] = field.value;
            }

            ServicesAPI.methods.apiPOST({
              url: 'models',
              function: 'registrar_campo',
              params: params
            }, () => {
              this.RightModal = false;
              this.setDefaultRightModal();
              this.getModels();
            });
            break;
        }
      },
      getModels() {
        ServicesAPI.methods.apiGET({
          url: 'models',
          function: 'obtener_modelos',
          params: {}
        }, res => {

          if (res.code != 200)
            this.$vs.notify({
              title: res.code,
              text: res.message,
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-alert-circle'
            });

          this.models = res.data.ticket_modelo;
        });
      },
      getModel(model) {

        ServicesAPI.methods.apiGET({
          url: 'models',
          function: 'obtener_modelo',
          params: {
            idticket_modelo: model
          }
        }, res => {

          if (res.code != 200)
            this.$vs.notify({
              title: res.code,
              text: res.message,
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-alert-circle'
            });

          const dataModel = res.data.ticket_modelo[0];

          this.dataRightModal = {
            name: 'Editar estado',
            id: 'editModel',
            data: dataModel,
            fields: [
              {text: 'Nombre', name: 'nombre', type: 'text', required: true, value: dataModel.nombre},
              {
                text: 'Estado', name: 'activo', type: 'select', required: true, options: [
                  {value: 0, text: 'Inactivo'},
                  {value: 1, text: 'Activo'}
                ],
                value: dataModel.activo
              },
              {text: 'Descripción', name: 'descripcion', type: 'textbox', required: true, value: dataModel.descripcion},
            ],
            txtSendBtn: 'Editar'
          };
          this.RightModal = true;
        });
      },
      checkMove: function (e) {

        const fields = this.models.filter(model => model.idticket_modelo == e)[0].D_idticket_modelo;
        var params = [], pos = 1;

        for (const field of fields) {
          params.push({
            idticket_modelo_campo: field.idticket_modelo_campo,
            posicion: pos
          });
          pos++;
        }

        ServicesAPI.methods.apiPUT({
          url: 'models',
          function: 'ordenar_campos',
          params: params
        });
      },
    },
    mounted() {
      this.isMounted = true;
      this.getModels();
      this.getFieldTypes()
    },
    components: {
      RightModal,
      draggable
    }
  }

</script>

<style lang="scss">
  #content {
    div {
      margin-bottom: 15px;
    }
  }
</style>
